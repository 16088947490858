.shine-text {
    color: #fff;
    -webkit-mask-image: linear-gradient(
      -60deg,
      rgba(0, 0, 0, 0.3) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.3) 70%
    );
    -webkit-mask-size: 200%;
    animation: shine 3s linear infinite;
  }
  
  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
  }



.cardContainer{
    background-color: #0C0E1A;
    background-image: radial-gradient(circle at center, rgba(255, 255, 255, 0.05) 2%, transparent 45%);
    background-size: 200% 200%; /* Tamaño inicial */
    animation: moveCircle 30s linear infinite; /* Animación para mover el círculo */
}

@keyframes moveCircle {
    0% {
        background-position: 0% 0%; /* Posición inicial */
    }
    50% {
        background-position: 100% 100%; /* Posición final */
    }
    100% {
        background-position: 0% 0%; /* Posición inicial */
    }
}